<template>
    <div :key="pageKey">
        <appic-page-title-bar ref="breadcrumbBar" :documentTitle="updateMode ? Purchaseorder__title : ''" help-page-component="AddPurchaseOrder"></appic-page-title-bar>
        <app-section-loader :status="loader"/>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <PurchaseOrderAlert
                    :alert="$t('message.closeAlert')"
                    class="mt-3"
                    mode="add"
                    type="warning"
                    v-if="addMode && newPurchaseOrderUnsaved"
                />
                <PurchaseOrderAlert
                    :alert="$t('message.closeAlert')"
                    class="mt-3"
                    mode="update"
                    type="warning"
                    v-if="updateMode && updatedPurchaseOrderUnsaved"
                />
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-form lazy-validation ref="poForm" v-model="validForm">
                        <v-overlay
                            :value="loader"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                    ></v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-expansion-panels
                            accordion
                            v-model="panel"
                            multiple
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel = []; updatePanelValue()">{{ $t('message.general') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <PoGeneralInfo v-if="loader"/>
                                    <template v-if="!loader">
                                        <v-layout row pt-6 v-if="false">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center primary lighten-4">{{ $t('message.loadActivePurchaseOrder') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items="allActivePurchaseOrders"
                                                        :placeholder="$t('message.startTypingToSearch')"
                                                        dense
                                                        hide-details="auto"
                                                        item-value="Purchaseorder.id"
                                                        item-text="Purchaseorder.title"
                                                        required
                                                        solo
                                                        @change="loadAnotherActivePurchaseOrder($event)"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <div class="d-flex flex-column py-1">
                                                            <span class="font-weight-bold">{{ item.Purchaseorder.title }}</span>
                                                            <span class="font-xs"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                            <span class="font-xs"> {{ $t('message.supplier') + ': ' + item.Supplier.title }} </span>
                                                        </div>
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row pt-3 v-if="addMode == true">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.relatedContract') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items="allUnpurchasedContracts"
                                                        :placeholder="$t('message.startTypingToSearch')"
                                                        :value="Purchaseorder__contract_id"
                                                        dense
                                                        hide-details="auto"
                                                        item-value="Contract.id"
                                                        item-text="Contract.title"
                                                        required
                                                        return-object
                                                        solo
                                                        @change="unPurchasedContract = $event"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <div class="d-flex flex-column py-1">
                                                            <span class="font-weight-bold">{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                                                            <span class="font-sm"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                            <span class="font-sm"> {{ $t('message.supplier') + ': ' + item.Supplier.title }} </span>
                                                        </div>
                                                    </template>
                                                    <template v-slot:selection="{item}">
                                                        <span>{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row :class="addMode ? '' : 'pt-3'">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.purchaseOrderDate') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-menu
                                                        ref="purchaseOrderDateMenu"
                                                        v-model="purchaseOrderDateMenu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        max-width="290px"
                                                        min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                                :label="Purchaseorder__podate ? '' : $t('message.required')"
                                                                :rules="[...validationRules.formattedDate]"
                                                                :value="computedPurchaseOrderDateFormatted"
                                                                append-icon="event"
                                                                class="custom force-text-left"
                                                                clearable
                                                                hint="DD/MM/YY format"
                                                                hide-details="auto"
                                                                background-color="white"
                                                                persistent-hint
                                                                readonly
                                                                solo
                                                                v-on="on"
                                                                @click:clear="Purchaseorder__podate = null"
                                                                @click:append="purchaseOrderDateMenu = true"
                                                        />
                                                    </template>
                                                    <v-date-picker
                                                            v-model="Purchaseorder__podate"
                                                            @input="purchaseOrderDateMenu = false"
                                                            first-day-of-week="1"
                                                            locale-first-day-of-year="4"
                                                            show-week
                                                    />
                                                </v-menu>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.purchaseOrderNumber') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-text-field
                                                        :value="Purchaseorder__title"
                                                        :label="Purchaseorder__title ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                                        autocomplete="new-password"
                                                        class="force-text-left"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="Purchaseorder__title = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5  py-1 class="align-center">{{ $t('message.contractNumber') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 pl-6>{{ Purchaseorder__Contract__version != null ? [Purchaseorder__Contract__title, Purchaseorder__Contract__revision_no , Purchaseorder__Contract__partial_no].filter(Boolean).join('-') : Purchaseorder__Contract__title }}</v-flex>
                                        </v-layout>
                                        <v-layout row v-if="Purchaseorder__Contract__salestype_id != 3">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.buyer') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 pl-6>{{ Purchaseorder__Office__title }}</v-flex>
                                        </v-layout>
                                        <v-layout row v-if="Purchaseorder__Contract__salestype_id != 3">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.consignee') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 pl-6>{{ Purchaseorder__Customer__title }}</v-flex>
                                        </v-layout>
                                        <v-layout row v-if="Purchaseorder__Contract__salestype_id == 3">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.buyerConsignee') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 pl-6>{{ Purchaseorder__Office__title }}</v-flex>
                                        </v-layout>
                                        <v-layout row v-if="Purchaseorder__Contract__salestype_id == 3">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.warehousePurchaseRequest') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 pl-6>{{ Purchaseorder__Wpr__title }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplier') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 v-if="Purchaseorder__Contract__salestype_id == 3">
                                                <v-autocomplete
                                                        :items=allSuppliers
                                                        :label="Purchaseorder__Contract__supplier_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-text="Supplier.title"
                                                        item-value="Supplier.id"
                                                        solo
                                                        v-model="Purchaseorder__Contract__supplier_id"
                                                        @change="supplierChanged"
                                                />
                                            </v-flex>
                                            <v-flex xs6 lg4 md6 py-1 pl-6 v-else>{{ Purchaseorder__Supplier__title }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplierTerms') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items=allSupplierPaymentTerms
                                                        :label="Purchaseorder__paymentstatement_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-value="Paymentterm.id"
                                                        item-text="Paymentterm.title"
                                                        required
                                                        solo
                                                        v-model="Purchaseorder__paymentstatement_id"
                                                >
                                                    <template v-slot:item="{ item }">
                                                        <div class="d-flex flex-column">
                                                            <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                            <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                        </div>
                                                    </template>
                                                    <template v-slot:selection="{ item }">
                                                        <div class="d-flex flex-column">
                                                            <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                            <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                        </div>
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.incoterm') }}</v-flex>
                                            <v-flex xs6 lg2 md3 py-1>
                                                <v-select
                                                        :items="contractIncoTerms"
                                                        :label="Purchaseorder__incoterm_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-value="Incoterm.id"
                                                        item-text="Incoterm.title"
                                                        required
                                                        solo
                                                        v-model="Purchaseorder__incoterm_id"
                                                        @change="incotermChanged($event)"
                                                />
                                            </v-flex>
                                            <v-flex xs6 lg2 md3 py-1 pl-0>
                                                <v-select
                                                        :items="incotermports"
                                                        :label="Purchaseorder__incotermport ? '' : $t('message.required')"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-value="value"
                                                        item-text="title"
                                                        required
                                                        solo
                                                        v-model="Purchaseorder__incotermport"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.shippingWeekStatement') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-text-field
                                                        :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                                        :value="Purchaseorder__shippingweekstatement"
                                                        autocomplete="new-password"
                                                        class="force-text-left"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="Purchaseorder__shippingweekstatement = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.termsDeliveryRemarks') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-textarea
                                                        :rules="[...validationRules.paragraph]"
                                                        :value="Purchaseorder__incotermremark"
                                                        class="elevation-0 pt-0"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        rows="3"
                                                        solo
                                                        @change="Purchaseorder__incotermremark = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="false">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.certification') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items=certifications
                                                        :label="Purchaseorder__fsc ? '' : $t('message.required')"
                                                        :value="Purchaseorder__fsc"
                                                        clearable
                                                        dense
                                                        item-text="Certification.title"
                                                        item-value="Certification.id"
                                                        hide-details="auto"
                                                        solo
                                                        required
                                                        @change="Purchaseorder__fsc = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.poSigned') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-checkbox
                                                        :true-value="1"
                                                        autocomplete="new-password"
                                                        class="mt-0"
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Purchaseorder__Contract__isposigned"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout v-if="Purchaseorder__Contract__salestype_id == 3">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.origin') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items=allCountries
                                                        :label="Purchaseorder__Contract__productorigin_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        item-text="Country.name"
                                                        item-value="Country.id"
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Purchaseorder__Contract__productorigin_id"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout v-if="Purchaseorder__Contract__salestype_id == 3">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.pol') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items=allShippingLoadingPorts
                                                        :item-text="item => item.Country.name ? item.Shippingport.title + '  -  ' + item.Country.name : item.Shippingport.title"
                                                        :item-value="'Shippingport.id'"
                                                        :label="Purchaseorder__Contract__loadingport_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Purchaseorder__Contract__loadingport_id"
                                                >
                                                    <template v-slot:selection="data">
                                                        {{ data.item.Country.name ? data.item.Shippingport.title + '-' + data.item.Country.name : data.item.Shippingport.title }}
                                                    </template>
                                                    <template v-slot:item="data">
                                                        {{ data.item.Country.name ? data.item.Shippingport.title + '-' + data.item.Country.name : data.item.Shippingport.title }}
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout v-if="Purchaseorder__Contract__salestype_id == 3">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.pod') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items=allShippingDischargePorts
                                                        :label="Purchaseorder__Contract__shippingport_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        item-text="Shippingport.title"
                                                        item-value="Shippingport.id"
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Purchaseorder__Contract__shippingport_id"
                                                >
                                                    <template v-slot:selection="data">
                                                        {{ data.item.Country.name ? data.item.Shippingport.title + ' - ' + data.item.Country.name : data.item.Shippingport.title }}
                                                    </template>
                                                    <template v-slot:item="data">
                                                        {{ data.item.Country.name ? data.item.Shippingport.title + ' - ' + data.item.Country.name : data.item.Shippingport.title }}
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout v-if="Purchaseorder__Contract__salestype_id == 3">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.destination') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items=allCountries
                                                        :label="Purchaseorder__Contract__destinatione_id ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        item-text="Country.name"
                                                        item-value="Country.id"
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Purchaseorder__Contract__destinatione_id"
                                                >
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout v-if="Purchaseorder__Contract__salestype_id == 3">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.measurementSystem') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row">
                                                <v-radio-group
                                                        class="mt-0"
                                                        dense
                                                        hide-details="auto"
                                                        row
                                                        v-model="Purchaseorder__Contract__ms_id"
                                                >
                                                    <v-radio :label="$t('message.metric')" :value="1"/>
                                                    <v-radio :label="$t('message.imperial')" :value="2"/>
                                                </v-radio-group>
                                                <div class="font-sm font-italic">({{ $t('message.switchToFilterThickness') }})</div>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplierSpecificRequests') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="red--text darken-1">{{ Purchaseorder__Supplier__remarks != null ? Purchaseorder__Supplier__remarks : 'N/A' }}</v-flex>
                                        </v-layout>
                                    </template>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel = []; updatePanelValue()">{{ $t('message.specifications') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <Transition>
                                        <PoItemList v-if="loader"/>
                                    </Transition>
                                    <template v-if="!loader">
                                        <v-flex lg12 mb-0 pb-0 mt-3 pt-0 v-if="Purchaseorder__contract_id == null">
                                            <v-alert
                                                    border="left"
                                                    color="warning"
                                                    dense
                                                    icon="warning"
                                                    text
                                                    width="500px"
                                            >{{ $t('message.errors.noRelatedContractSelected') }}</v-alert>
                                        </v-flex>
                                        <WareHousePurchaseOrderItemList :updateMode="this.updateMode" v-if="Purchaseorder__contract_id != null && Purchaseorder__Contract__salestype_id == 3"/>
                                        <PurchaseOrderItemListNew :updateMode="this.updateMode" v-if="Purchaseorder__contract_id != null && Purchaseorder__Contract__salestype_id != 3"/>
                                    </template>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel v-if="$can('update','AgentCommission') && Purchaseorder__Contract__salestype_id === 3 && Purchaseorder__Agent__has_commission === 1 && updateMode === true">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel = []; updatePanelValue()">{{ $t('message.agentCommission') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.agent') + ' - ' + Purchaseorder__Agent__otsname }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row align-center">
                                            <v-checkbox
                                                :label="$t('message.yes')"
                                                :true-value="1"
                                                class="mt-0"
                                                dense
                                                hide-details="auto"
                                                solo
                                                v-model="Purchaseorder__has_agent_commission"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <template v-if="Purchaseorder__has_agent_commission == 1">
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.amount') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row align-center">
                                                <v-text-field
                                                    :value="Purchaseorder__agent_commission_calc__unit_price"
                                                    readonly
                                                    autocomplete="off"
                                                    dense
                                                    hide-details="auto"
                                                    required
                                                    solo
                                                    style="background-color: lightgrey !important;"
                                                    suffix="USD/M3"
                                                />
                                                <div class="mx-2 pt-2 font-weight-bold"> X </div>
                                                <v-text-field
                                                    :label="Purchaseorder__agent_commission_calc__quantity ? '' : $t('message.required')"
                                                    :rules="[...validationRules.required, ...validationRules.amount, ...validationRules.minimumZero]"
                                                    :value="Purchaseorder__agent_commission_calc__quantity"
                                                    autocomplete="off"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    min="0"
                                                    required
                                                    solo
                                                    suffix="M3"
                                                    @change="updateCommissionForAgent('Purchaseorder__agent_commission_calc__quantity',$event)"
                                                />
                                                <div class="mx-2 pt-2 font-weight-bold"> = </div>
                                                <v-text-field
                                                    :value="Purchaseorder__agent_commission_amount"
                                                    readonly
                                                    autocomplete="off"
                                                    dense
                                                    hide-details="auto"
                                                    required
                                                    solo
                                                    style="background-color: lightgrey !important;"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.paidOn') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="font-weight-bold">
                                                <v-menu
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    max-width="290px"
                                                    min-width="290px"
                                                    ref="commissionInvoiceDateMenu"
                                                    v-model="agentCommissionDateMenu"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                            :rules="[...validationRules.formattedDate]"
                                                            :value="computedAgentCommissionDateFormatted"
                                                            append-icon="event"
                                                            background-color="white"
                                                            clearable
                                                            hide-details="auto"
                                                            hint="DD/MM/YY format"
                                                            persistent-hint
                                                            solo
                                                            v-on="on"
                                                            @click:clear="Purchaseorder__agent_commission_paid = null"
                                                            @click:append="agentCommissionDateMenu = true"
                                                        />
                                                    </template>
                                                    <v-date-picker
                                                        first-day-of-week="1"
                                                        locale-first-day-of-year="4"
                                                        show-week
                                                        v-model="Purchaseorder__agent_commission_paid"
                                                        @input="agentCommissionDateMenu = false"
                                                    />
                                                </v-menu>
                                                <v-btn
                                                    :loading="loading.saveCommission"
                                                    style="width: 50% !important;"
                                                    color="default"
                                                    small
                                                    @click="saveAgentCommission()"
                                                >{{ $t('message.saveCommission') }}</v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </template>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel = []; updatePanelValue()">{{ $t('message.relatedFiles') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <FileUploadList
                                            :file-name-prefix="fileNamePrefix"
                                            :files="files"
                                            :module="'purchaseorder'"
                                            :page-key="fileListPageKey"
                                            :updateMode="this.updateMode"
                                            class="pt-3"
                                            @upload-file-done="addUploadedFile"
                                    />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel = []; updatePanelValue()">{{ $t('message.printPreferences') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.showCustomerAddress') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-checkbox
                                                    :true-value="1"
                                                    class="mt-0"
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    v-model="Purchaseorder__showcustomerasbuyer"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.notifyingParty') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-radio-group
                                                    class="mt-0"
                                                    dense
                                                    hide-details="auto"
                                                    row
                                                    v-model="Purchaseorder__notify_address_show"
                                            >
                                                <v-radio :label="$t('message.show')" :value="1"/>
                                                <v-radio :label="$t('message.tba')" :value="0"/>
                                            </v-radio-group>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.preferredFooterNotes') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-btn @click.stop="footerNotesDialog = true" small>{{ $t('message.manage') }}</v-btn>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.printTwoPages') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-checkbox
                                                    :true-value="1"
                                                    class="mt-0"
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    v-model="Purchaseorder__Contract__longform"
                                            />
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel :disabled="$route.matched.some(({name}) => name == 'add_purchaseorder')">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel = []; updatePanelValue()">{{ $t('message.history') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <DocumentHistory asset="purchaseorders" :asset_id="Purchaseorder__id"></DocumentHistory>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-layout row px-3 pt-2 mb-3>
                            <v-flex d-flex class="align-center">
                                <v-btn
                                        class="ml-3"
                                        small
                                        @click.stop="previewDialog = true"
                                        v-if="false"
                                >{{ $t('message.previewPurchaseOrder') }}
                                </v-btn>
                                <PrintPurchaseOrderButton
                                        :update-mode="updateMode"
                                        :purchase-order-id="Purchaseorder__id"
                                        :purchase-order-title="Purchaseorder__title"
                                        :list-mode="false"
                                        :mergeable-docs="mergeableDocs"
                                />
                                <v-btn
                                        :loading="loading.reset"
                                        @click="resetPurchaseOrderForm"
                                        class="ml-2"
                                        color="default"
                                        small
                                        v-if="addMode"
                                ><v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                                </v-btn>
                                <v-btn
                                        :loading="loading.add"
                                        class="ml-3"
                                        color="info"
                                        small
                                        @click="addPurchaseOrder()"
                                        v-if="addMode == true && $can('create','Po')"
                                >{{ $t('message.create') }}
                                </v-btn>
                                <v-btn
                                        small
                                        class="ml-3 lighten-2"
                                        v-if="updateMode && [3].includes(Purchaseorder__Contract__salestype_id)"
                                        @click.stop="cloneThisPurchaseOrder()"
                                >
                                    <v-icon small>far fa-copy</v-icon>
                                    <span class="ml-1">{{ $t('message.clone') }}</span>
                                </v-btn>
<!--                                <v-btn-->
<!--                                    small-->
<!--                                    class="ml-3 lighten-2"-->
<!--                                    v-if="updateMode && [3].includes(Purchaseorder__Contract__salestype_id) && [1,2].includes(Purchaseorder__Contract__salestatus_id)"-->
<!--                                    @click.stop="splitThisPurchaseOrder()"-->
<!--                                >-->
<!--                                    <v-icon small>fas fa-divide</v-icon>-->
<!--                                    <span class="ml-1">{{ $t('message.createPartialShipment') }}</span>-->
<!--                                </v-btn>-->
                                <v-btn
                                        :loading="loading.save"
                                        class="ml-3"
                                        color="info"
                                        small
                                        @click="savePurchaseOrder()"
                                        v-if="addMode == false && Purchaseorder__Contract__salestatus_id == 1"
                                >{{ $t('message.save') }}
                                </v-btn>
                                <v-btn
                                        :loading="loading.cancel"
                                        class="ml-3"
                                        color="error"
                                        small
                                        v-if="addMode == false && Purchaseorder__Contract__salestatus_id == 1"
                                        @click="cancelPurchaseOrder(Purchaseorder__id)"
                                >{{ $t('message.cancel') }}
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </app-card>
            </v-layout>
        </v-container>
        <v-dialog
            overlay=false
            scrollable
            transition="dialog-bottom-transition"
            v-model="previewDialog"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.purchaseOrderPreview') }}</v-card-title>
                <v-card-text class="pa-3">
                    <PurchaseOrderPreview></PurchaseOrderPreview>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        class="ml-3"
                        color="default"
                        small
                        @click="previewDialog = false"
                    >{{ $t('message.backToEditing') }}
                    </v-btn>
                    <v-btn
                        :loading="loading.add"
                        class="ml-3"
                        color="info"
                        small
                        v-if="addMode == true"
                        @click="addPurchaseOrder()"
                    >{{ $t('message.addPurchaseOrder') }}
                    </v-btn>
                    <v-btn
                        :loading="loading.save"
                        class="ml-3"
                        color="info"
                        small
                        v-if="addMode == false"
                        @click="savePurchaseOrder()"
                    >{{ $t('message.saveChanges') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            persistent
            v-model="footerNotesDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.manageFooterNotes') }}</v-card-title>
                <v-card-text>
                    <v-list dense>
                        <v-list-item v-for="item in purchaseOrderFooterNotes" :key="item.id" v-if="item.id != 10">
                            <v-list-item-action>
                                <v-checkbox
                                    :value="item.id"
                                    dense
                                    hide-details="auto"
                                    v-model="Purchaseorder__footer_notes"
                                />
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title class="font-sm wrap-text">{{ item.text }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="footerNotesDialog = false"
                        class="ml-3"
                        color="default"
                        small
                    >{{ $t('message.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <ClonePurchaseOrder
            :dialog.sync="cloneDialog"
            @dialog-closed="cloneDialogClosed"
        />
        <SplitPurchaseOrder
            :dialog.sync="splitDialog"
            @dialog-closed="splitDialogClosed"
        />
<!--        <PrintPurchaseOrderHtml :purchase-order-id="purchaseOrderId" :view-pdf="viewPdf" :key="pdfComponentKey" @pdf-has-downloaded="viewPdf = false"></PrintPurchaseOrderHtml>-->
    </div>
</template>

<script>
import {formatDate, isObject, scrollToFirstFormValidationError} from "Helpers/helpers";
import {mapGetters, mapActions} from "vuex";
import {mapFields} from "vuex-map-fields";
import router from "../../../router";
import {mapFieldsPrefixed} from  'Helpers/vuex-map-fields-prefixed';
import {Decimal} from 'decimal.js'
import { v4 as uuidv4 } from 'uuid';
import PoGeneralInfo from "Components/Appic/Loading/PoGeneralInfo";

const AlertMissingValues = () => import("../../../components/Appic/AlertMissingValues");
const ClonePurchaseOrder = () => import("../../../components/Appic/ClonePurchaseOrder");
const DocumentHistory = () => import("../../../components/Appic/DocumentHistory");
// const FabPo = () => import("Components/Appic/FabPo");
const FileUploadList = () => import("../../../components/Appic/FileUploadList");
const PurchaseOrderAlert = () => import("../../../components/Appic/PurchaseOrderAlert");
const PurchaseOrderItemListNew = () => import("Components/Appic/PurchaseOrderItemListNew");
const WareHousePurchaseOrderItemList = () => import("Components/Appic/WareHousePurchaseOrderItemList");
const PurchaseOrderPreview = () => import("Components/Appic/PurchaseOrderPreview");
const SplitPurchaseOrder = () => import("../../../components/Appic/SplitPurchaseOrder");
// const PrintPurchaseOrderHtml = () => import('Views/v1/purchaseorders/PrintPurchaseOrderHtml');
const PoItemList = () => import("Components/Appic/Loading/PoItemList");
const PrintPurchaseOrderButton = () => import("Components/Appic/Printing/PrintPurchaseOrderButton");

export default {
    name: "AddPurchaseOrder",
    components: {
        PrintPurchaseOrderButton,
        PoGeneralInfo,
        AlertMissingValues,
        ClonePurchaseOrder,
        DocumentHistory,
        // FabPo,
        FileUploadList,
        // PrintPurchaseOrderHtml,
        PoItemList,
        PurchaseOrderAlert,
        PurchaseOrderItemListNew,
        PurchaseOrderPreview,
        SplitPurchaseOrder,
        WareHousePurchaseOrderItemList
    },
    beforeRouteEnter (to, from, next) {
        next()
    },
    props: ['purchaseOrderId'],
    title: 'PO: ',
    data() {
        return {
            agentCommissionDateMenu: false,
            cloneDialog: false,
            fileListPageKey: 0,
            footerNotesDialog: false,
            incotermports: [
                {value: null, title: 'None'},
                {value: 0, title: 'POD'},
                {value: 1, title: 'POL'},
                {value: 2, title: this.$t('message.doNotShow')},
            ],
            loader: false,
            loading: {
                add: false,
                reset: false,
                save: false,
                saveCommission: false
            },
            loadingActivePurchaseOrder: false,
            loadingRelatedContract: false,
            mergeableDocs: [],
            pageKey: Math.floor(Math.random() * 100),
            panel: [0,1],
            pdfComponentKey: 0,
            previewDialog: false,
            purchaseOrderDateFormatted: formatDate(new Date().toISOString().substr(0,10)),
            purchaseOrderDateMenu: false,
            // rules: {
            //     date: v => !!v || this.$t('message.required'),
            //     destination: v => !!v || this.$t('message.required'),
            //     incoterm: v => !!v || this.$t('message.required'),
            //     incotermPort: v => typeof v === "number" || this.$t('message.required'),
            //     number: v => !!v || this.$t('message.required'),
            //     origin: v => !!v || this.$t('message.required'),
            //     pod: v => !!v || this.$t('message.required'),
            //     pol: v => !!v || this.$t('message.required'),
            //     shippingWeekStatement: v => !!v || this.$t('message.required'),
            //     supplier: v => !!v || this.$t('message.required'),
            //     supplierTerm: v => !!v || this.$t('message.required')
            // },
            splitDialog: false,
            unPurchasedContract: null,
            validForm: true
        }
    },
    asyncComputed: {},
    computed: {
        statePrefix(){
            if(this.updateMode){
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('purchaseorder',{
            Purchaseorder__Agent__id: 'Purchaseorder.Agent.id',
            Purchaseorder__Agent__has_commission: 'Purchaseorder.Agent.has_commission',
            Purchaseorder__Agent__otsname: 'Purchaseorder.Agent.otsname',
            Purchaseorder__Contract__id: 'Purchaseorder.Contract.id',
            Purchaseorder__Contract__containercount: 'Purchaseorder.Contract.containercount',
            Purchaseorder__Contract__containersize_id: 'Purchaseorder.Contract.containersize_id',
            Purchaseorder__Contract__isposigned: 'Purchaseorder.Contract.isposigned',
            Purchaseorder__Contract__destinatione_id: 'Purchaseorder.Contract.destinatione_id',
            Purchaseorder__Contract__loadingport_id: 'Purchaseorder.Contract.loadingport_id',
            Purchaseorder__Contract__longform: 'Purchaseorder.Contract.longform',
            Purchaseorder__Contract__ms_id: 'Purchaseorder.Contract.ms_id',
            Purchaseorder__Contract__partial_no: 'Purchaseorder.Contract.partial_no',
            Purchaseorder__Contract__productorigin_id: 'Purchaseorder.Contract.productorigin_id',
            Purchaseorder__Contract__revision_no: 'Purchaseorder.Contract.revision_no',
            Purchaseorder__Contract__salescontact_id: 'Purchaseorder.Contract.salescontact_id',
            Purchaseorder__Contract__salestatus_id: 'Purchaseorder.Contract.salestatus_id',
            Purchaseorder__Contract__salestype_id: 'Purchaseorder.Contract.salestype_id',
            Purchaseorder__Contract__shippingport_id: 'Purchaseorder.Contract.shippingport_id',
            Purchaseorder__Contract__supplier_id: 'Purchaseorder.Contract.supplier_id',
            Purchaseorder__Contract__title: 'Purchaseorder.Contract.title',
            Purchaseorder__Contract__version: 'Purchaseorder.Contract.version',
            Purchaseorder__Customer__title: 'Purchaseorder.Customer.title',
            Purchaseorder__Office__title: 'Purchaseorder.Office.title',
            Purchaseorder__documents: 'Purchaseorder.documents',
            Purchaseorder__id: 'Purchaseorder.id',
            Purchaseorder__agent_commission_calc__setting_id: 'Purchaseorder.agent_commission_calc.setting_id',
            Purchaseorder__agent_commission_calc__unit_price: 'Purchaseorder.agent_commission_calc.unit_price',
            Purchaseorder__agent_commission_calc__quantity: 'Purchaseorder.agent_commission_calc.quantity',
            Purchaseorder__agent_commission_amount: 'Purchaseorder.agent_commission_amount',
            Purchaseorder__agent_commission_paid: 'Purchaseorder.agent_commission_paid',
            Purchaseorder__contract_id: 'Purchaseorder.contract_id',
            Purchaseorder__footer_notes: 'Purchaseorder.footer_notes',
            Purchaseorder__fsc: 'Purchaseorder.fsc',
            Purchaseorder__has_agent_commission: 'Purchaseorder.has_agent_commission',
            Purchaseorder__incoterm_id: 'Purchaseorder.incoterm_id',
            Purchaseorder__incotermport: 'Purchaseorder.incotermport',
            Purchaseorder__incotermremark: 'Purchaseorder.incotermremark',
            Purchaseorder__notify_address_show: 'Purchaseorder.notify_address_show',
            Purchaseorder__paymentstatement_id: 'Purchaseorder.paymentstatement_id',
            Purchaseorder__paymentterm_id: 'Purchaseorder.paymentterm_id',
            Purchaseorder__podate: 'Purchaseorder.podate',
            Purchaseorder__shippingweekstatement: 'Purchaseorder.shippingweekstatement',
            Purchaseorder__shipremark: 'Purchaseorder.shipremark',
            Purchaseorder__showcustomerasbuyer: 'Purchaseorder.showcustomerasbuyer',
            Purchaseorder__title: 'Purchaseorder.title',
            Purchaseorder__Supplier__title: 'Purchaseorder.Supplier.title',
            Purchaseorder__Supplier__paymentstatement_id: 'Purchaseorder.Supplier.paymentstatement_id',
            Purchaseorder__Supplier__remarks: 'Purchaseorder.Supplier.remarks',
            Purchaseorder__Wpr__title: 'Purchaseorder.Wpr.title',
        },'statePrefix'),
        ...mapFields('purchaseorder',{
            newPurchaseOrderSaved: 'newPurchaseOrderSaved',
            newPurchaseOrderUnsaved: 'newPurchaseOrderUnsaved',
            updatedPurchaseOrderUnsaved: 'updatedPurchaseOrderUnsaved',
            updatedPurchaseOrderSaved: 'updatedPurchaseOrderSaved'
        }),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters('contract',{
            allUnpurchasedContracts: 'allUnpurchasedContracts'
        }),
        ...mapGetters('country',{
            allCountries: 'allCountries'
        }),
        ...mapGetters('document',{
            allDocuments: 'allDocuments',
            // allNewPurchaseOrderDocuments: 'allNewPurchaseOrderDocuments',
            // allPurchaseOrderDocuments: 'allPurchaseOrderDocuments',
        }),
        ...mapGetters('incoterm', {
            contractIncoTerms: 'contractIncoTerms'
        }),
        ...mapGetters('paymentterm',{
            allSupplierPaymentTerms: 'allSupplierPaymentTerms'
        }),
        ...mapGetters('purchaseorder',{
            allActivePurchaseOrders: 'allActivePurchaseOrders',
        }),
        ...mapGetters('shippingport', {
            allShippingLoadingPorts: 'allShippingLoadingPorts',
            allShippingDischargePorts: 'allShippingDischargePorts'
        }),
        ...mapGetters('supplier', {
            allSuppliers: 'allSuppliers',
            findSupplierById: 'findSupplierById'
        }),
        ...mapGetters([
            'certifications',
            'gpTaxCodes',
            'gstRates',
            'incoterms',
            'offices',
            'purchaseOrderFooterNotes',
            'statuses',
            'validationRules'
        ]),
        addMode: function () {
            return this.$route.matched.some(({name}) => name === 'add_purchaseorder')
        },
        computedAgentCommissionDateFormatted () {
            return formatDate(this.Purchaseorder__agent_commission_paid)
        },
        computedPurchaseOrderDateFormatted () {
            return formatDate(this.Purchaseorder__podate)
        },
        fileNamePrefix() {
            let prefix = ''
            if(this.Purchaseorder__title != null && this.Purchaseorder__title != ''){
                prefix = this.Purchaseorder__title
            }
            return prefix
        },
        files () {
            let files = [];
            if(this.addMode){
                // files = this.allNewPurchaseOrderDocuments
                files = this.allDocuments.NewPurchaseOrderDocuments
            } else if(this.updateMode){
                // files = this.allPurchaseOrderDocuments
                files = this.allDocuments.PurchaseOrderDocuments
            }
            return files
        },
        updateMode: function () {
            return this.$route.matched.some(({name}) => name === 'update_purchaseorder')
        },
    },
    methods: {
        ...mapActions('contract',{
            getAllActiveContracts: 'getAllActiveContracts',
            resetAllContracts: 'resetAllContracts'
        }),
        ...mapActions('country',{
            getAllCountries: 'getAllCountries'
        }),
        ...mapActions('document',{
            resetAllDocuments: 'resetAllDocuments',
            getPurchaseOrderDocumentsById: 'getPurchaseOrderDocumentsById',
            getStandardDocuments: 'getStandardDocuments',
            getAllStandardShippingDocuments: 'getAllStandardShippingDocuments'
        }),
        ...mapActions('incoterm', {
            getContractIncoTerms: 'getContractIncoTerms'
        }),
        ...mapActions('paymentterm',{
            getAllPaymentTerms: 'getAllPaymentTerms'
        }),
        ...mapActions('purchaseorder',{
            cancelPurchaseOrderById: 'cancelPurchaseOrderById',
            clonePurchaseOrder: 'clonePurchaseOrder',
            createPurchaseOrder: 'createPurchaseOrder',
            getAllActivePurchaseOrders: 'getAllActivePurchaseOrders',
            getPurchaseOrderToUpdateById: 'getPurchaseOrderToUpdateById',
            loadUnpurchasedContract: 'loadUnpurchasedContract',
            resetAllActivePurchaseOrders: 'resetAllActivePurchaseOrders',
            resetCurrentPurchaseOrder: 'resetCurrentPurchaseOrder',
            splitPurchaseOrder: 'splitPurchaseOrder',
            updateAgentCommission: 'updateAgentCommission',
            updatePurchaseOrder: 'updatePurchaseOrder',
        }),
        ...mapActions('purchaseorderitem',{
            getSalesItemsByContract: 'getSalesItemsByContract',
            resetPurchaseOrderItems: 'resetPurchaseOrderItems',
        }),
        ...mapActions('shippingport', {
            getAllShippingPorts: 'getAllShippingPorts',
        }),
        ...mapActions('supplier', {
            resetAllSuppliers: 'resetAllSuppliers',
            getAllSuppliers: 'getAllSuppliers'
        }),
        addPurchaseOrder () {
            if(this.$refs.poForm.validate()) {
                this.loading.add = true
                this.createPurchaseOrder()
                    .then((response) => {
                        if (response.status == 'success') {
                            this.$toast.success(this.$t('message.successes.purchaseOrderAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.newPurchaseOrderUnsaved = false
                            this.newPurchaseOrderSaved = true
                            // reset new purchase order state
                            this.resetCurrentPurchaseOrder().then(() => {
                                //reset new purchaseorderitems state
                                this.resetPurchaseOrderItems(this.statePrefix).then(() => {
                                    this.resetAllActivePurchaseOrders().then(() => {
                                        router.push({
                                            name: 'update_purchaseorder',
                                            params: {purchaseOrderId: response.purchaseorder_id}
                                        }).catch(err => {
                                            this.$toast.error(err,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                    })
                                })
                            })
                        } else {
                            if(isObject(response.message)){
                                //extract validation errors
                                const errors = Object.values(response.message)
                                errors.forEach(errorObj => {
                                    if (Array.isArray(errorObj)) {
                                        errorObj.forEach(error => {
                                            this.$toast.error(error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                    } else {
                                        this.$toast.error(this.$t('message.errors.purchaseOrderNotAdded'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.purchaseOrderNotAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.purchaseOrderNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.purchaseOrderNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
                this.loading.add = false
                scrollToFirstFormValidationError()
            }
        },
        addUploadedFile (fileObject){
            if(this.Purchaseorder__documents.length == 0){
                this.Purchaseorder__documents.push({AssetDocument: fileObject.AssetDocument})
            } else {
                //add/replace file in existing list
                const doctype_id = fileObject.AssetDocument.doctype_id
                let documents = []
                this.Purchaseorder__documents.forEach((item, index)=>{
                    if(doctype_id != item.AssetDocument.doctype_id){
                        documents.push(item)
                    }
                })
                const newFile = {
                    AssetDocument: fileObject.AssetDocument
                }
                documents.push(newFile)
                this.Purchaseorder__documents.length = 0
                documents.forEach(document => {
                    this.Purchaseorder__documents.push(document)
                })

                if(this.updateMode == true){
                    this.savePurchaseOrder()
                }
            }
        },
        async cancelPurchaseOrder(val) {
            if(await this.$root.$confirm(this.$t('message.cancelPurchaseOrder'), this.$t('message.confirmations.continuePurchaseOrderCancelAction'), {color: 'orange'})){
                this.cancelPurchaseOrderById(val)
                    .then((response) => {
                        if(response.status == 'success'){
                            this.$toast.success(this.$t('message.successes.purchaseOrderDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            router.push({name: 'purchaseorder_listing'}).catch(err => {})
                            this.loading.cancel = false
                        } else {
                            this.$toast.error(this.$t('message.errors.purchaseOrderNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(()=>{
                        this.$toast.error(this.$t('message.errors.purchaseOrderNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        cloneDialogClosed () {
            this.cloneDialog = false;
        },
        cloneThisPurchaseOrder () {
            this.clonePurchaseOrder()
                .then(()=>{
                    this.cloneDialog = true
                })
        },
        dialogClosed () {
            this.dialogs.error = false;
            this.dialogs.error_message = ''
        },
        getMergeableDocs() {
            return new Promise((resolve, reject) => {
                try {
                    if(typeof this.Purchaseorder__documents !== 'undefined' && Array.isArray(this.Purchaseorder__documents)){
                        this.Purchaseorder__documents.forEach(doc => {
                            if(doc.AssetDocument.merge_attachments_to_main_pdf == 1){
                                this.mergeableDocs.push(doc.AssetDocument.location)
                            }
                        })
                    }
                    resolve('done')
                } catch(e) {
                    reject(e)
                }
            })
        },
        incotermChanged (val) {
            if(val == 1) this.Purchaseorder__incotermport = 1
            if(val == 2) this.Purchaseorder__incotermport = 0
        },
        loadAnotherActivePurchaseOrder (val) {
            router.push({name: 'update_purchaseorder', params: { purchaseOrderId : val}}).catch(err => {})
        },
        loadPurchaseOrderById (val){
            let self = this
            this.loader = true
            this.getPurchaseOrderToUpdateById(val)
                .then(()=>{
                    this.$title =  this.$t('message.titles.po') + ": " + this.Purchaseorder__title
                    const payload = {
                        contract_id: this.Purchaseorder__contract_id,
                        prefix: self.statePrefix
                    }
                    this.loader = false;
                    this.getSalesItemsByContract(payload)
                         .then(()=>{
                            this.Purchaseorder__documents = []
                            const ids = {
                                contract_id: this.Purchaseorder__contract_id,
                                purchaseorder_id: val
                            }
                            this.getPurchaseOrderDocumentsById(ids)
                                .then((documents)=>{
                                    this.Purchaseorder__documents = documents
                                    this.getMergeableDocs();
                                    this.loader = false;
                                    this.updatedPurchaseOrderUnsaved = false
                                })
                                .catch(() => {
                                    this.updatedPurchaseOrderUnsaved = false
                                    this.loader = false;
                                })
                        })
                        .catch(()=>{
                            this.updatedPurchaseOrderUnsaved = false
                            this.loader = false;
                        })
                })
                .catch(()=>{
                    this.updatedPurchaseOrderUnsaved = false
                    this.loader = false;
                    router
                        .push({name: 'not_found_main', params: {resource: 'PO'}})
                        .catch(() => {
                            console.log('error')
                        })
                })

        },
        async resetPurchaseOrderForm() {
            if (await this.$root.$confirm(this.$t('message.resetPurchaseOrderForm'), this.$t('message.confirmations.continuePurchaseOrderFormReset'), {color: 'orange'})) {
                this.loading.reset = true
                this.resetCurrentPurchaseOrder()
                    .then(() => {
                        this.resetPurchaseOrderItems(this.statePrefix).then(()=>{
                            setTimeout(() => {
                                this.newPurchaseOrderSaved = false
                                this.newPurchaseOrderUnsaved = false
                                this.loading.reset = false
                                this.$toast.success(this.$t('message.purchaseOrderFormResetNotification'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                            }, 500)
                        })
                    })
            }
        },
        saveAgentCommission() {
            this.loading.saveCommission = true;
            this.updateAgentCommission()
                .then((response) => {
                    if (response.status === 'success') {
                        this.$toast.success(this.$t('message.successes.agentCommissionUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.loading.saveCommission = false
                        this.updatedPurchaseOrderUnsaved = false
                    } else {
                        if(isObject(response.message)){
                            //extract validation errors
                            const errors = Object.values(response.message)
                            errors.forEach(errorObj => {
                                if (Array.isArray(errorObj)) {
                                    errorObj.forEach(error => {
                                        this.$toast.error(error,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    })
                                } else {
                                    this.$toast.error(this.$t('message.errors.agentCommissionNotUpdated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                            })
                        } else {
                            this.$toast.error(this.$t('message.errors.agentCommissionNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                        this.loading.saveCommission = false
                    }
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.agentCommissionNotUpdated'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.saveCommission = false
                })
        },
        savePurchaseOrder () {
            if(this.$refs.poForm.validate()) {
                this.loading.save = true
                this.updatePurchaseOrder()
                    .then((response) => {
                        if (response.status == 'success') {
                            const payload = {
                                contract_id: this.Purchaseorder__contract_id,
                                prefix: this.statePrefix
                            }
                            this.getSalesItemsByContract(payload)
                                .then(() => {
                                    const ids = {
                                        contract_id: this.Purchaseorder__contract_id,
                                        purchaseorder_id: this.Purchaseorder__id
                                    }
                                    this.getPurchaseOrderDocumentsById(ids)
                                        .then((documents) => {
                                            this.Purchaseorder__documents = documents
                                            this.getMergeableDocs()
                                            this.$toast.success(this.$t('message.successes.purchaseOrderUpdated'),
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'check_circle_outline'
                                                }
                                            )
                                            this.loading.save = false
                                            this.updatedPurchaseOrderUnsaved = false
                                            this.pdfComponentKey = Math.floor(Math.random() * 100)
                                        })
                                })
                                .catch(() => {
                                    this.$toast.error(this.$t('message.errors.purchaseOrderNotUpdated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                    this.loading.save = false
                                })
                        } else {
                            if(isObject(response.message)){
                                //extract validation errors
                                const errors = Object.values(response.message)
                                errors.forEach(errorObj => {
                                    if (Array.isArray(errorObj)) {
                                        errorObj.forEach(error => {
                                            this.$toast.error(error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                    } else {
                                        this.$toast.error(this.$t('message.errors.purchaseOrderNotUpdated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.purchaseOrderNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.purchaseOrderNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.purchaseOrderNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
                this.loading.save = false
                scrollToFirstFormValidationError()
            }
        },
        splitDialogClosed () {
            this.splitDialog = false;
        },
        splitThisPurchaseOrder () {
            this.splitPurchaseOrder()
                .then(()=>{
                    this.splitDialog = true
                })
        },
        supplierChanged () {
            let supplier = this.findSupplierById(this.Purchaseorder__Contract__supplier_id)
            if(supplier != null){
                this.Purchaseorder__Contract__productorigin_id = supplier.Country.id
            }
        },
        viewPdf () {
            let document = encodeURIComponent(this.Purchaseorder__title + '_PO')
            let tab = window.open(
                process.env.VUE_APP_PDF_SERVER
                + '/api/render/'
                + document
                + '?url='
                + process.env.VUE_APP_BASE_URL
                + '/v1/purchaseorders/print/'
                + this.purchaseOrderId
                + '/'
                + uuidv4()
                + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                , "_blank");
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        updateCommissionForAgent( field, value) {
            this[field] = value;

            const unitPrice = new Decimal(this.Purchaseorder__agent_commission_calc__unit_price + '');
            const quantity =  new Decimal(value + '');
            this.Purchaseorder__agent_commission_amount = unitPrice.times(quantity).toDecimalPlaces(2, Decimal.ROUND_DOWN).toFixed()

            // const originalAmount = new Decimal(this.Purchaseorder__amount + '');
            // const agentCommission = new Decimal(this.Purchaseorder__agent_commission_amount + '');
            // this.Purchaseorder__net_amount = originalAmount.minus(agentCommission).toFixed();

            //is this a fix?
            this.Purchaseorder__agent_commission_calc__unit_price = unitPrice.toFixed();
        },
        async updatePanelValue() {
            const _this = this;
            setTimeout(function(){
                if(_this.panel.length === 1 && (_this.panel[0] === 0 || _this.panel[0] === 1)) {
                    _this.panel.push(0);
                    _this.panel.push(1);
                }
            }, 600)
        },
    },
    watch: {
        Purchaseorder__paymentstatement_id (val) {
            let paymentterm = this.allSupplierPaymentTerms.find(d => d.Paymentterm.id == val)?.Paymentterm?.paymentterm_id
            if(paymentterm) {
                this.Purchaseorder__paymentterm_id = paymentterm
            }
        },
        unPurchasedContract (val){
            console.log('Loading un-purchased contract')
            this.loader = true
            let self = this
            setTimeout(function(){
                self.loadUnpurchasedContract(val)
                    .then(()=>{
                        let payload = {
                            contract_id: val.Contract.id,
                            prefix: self.statePrefix
                        }
                        self.getSalesItemsByContract(payload).then(()=>{
                            self.loader = false
                        })
                    })
                    .catch(() => {
                        self.loader = false
                    })
            },100)
        }
    },
    created(){
        if(this.allCountries.length === 0) this.getAllCountries()
        if(this.allActivePurchaseOrders.length === 0) this.getAllActivePurchaseOrders()
        if(this.allUnpurchasedContracts.length === 0) this.getAllActiveContracts()
        if(this.allShippingDischargePorts.length === 0 || this.allShippingLoadingPorts.length === 0) this.getAllShippingPorts()
        if(this.allSupplierPaymentTerms.length === 0) this.getAllPaymentTerms()
        if(this.allSuppliers.length === 0) this.getAllSuppliers()
        if(this.contractIncoTerms.length === 0) this.getContractIncoTerms()

        if(this.allDocuments.NewPurchaseOrderDocuments.length === 0) {
            this.getAllStandardShippingDocuments()
            this.getStandardDocuments()
        }

        if(this.$route.matched.some(({name}) => name === 'update_purchaseorder')) {
            this.loadPurchaseOrderById(this.purchaseOrderId)
        } else if(this.$route.matched.some(({name}) => name === 'add_purchaseorder')){
            this.newPurchaseOrderUnsaved = true;
            this.resetCurrentPurchaseOrder()
                .then(() => {
                    this.resetPurchaseOrderItems(this.statePrefix)
                })
        }
    },
    mounted () {
        this.$store.watch(
            function(state) {
                return state.appic.purchaseorder.update.Purchaseorder
            },
            () => {
                this.updatedPurchaseOrderUnsaved = true
            },
            { deep: true }
        )
        this.$store.watch(
            function(state) {
                return state.appic.purchaseorder.current.Purchaseorder
            },
            () => {
                this.newPurchaseOrderUnsaved = true
            },
            { deep: true }
        )
    }
}
</script>

<style>
    .force-text-left .v-text-field__slot input {
        text-align: left !important;
    }
    .v-dialog {
        min-width: 200px !important;
    }
    .wrap-text {
        white-space: normal;
    }
    .font-xs {
        font-size: small;
    }
    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.5s ease;
    }
    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
    .no-left-padding > div.v-expansion-panel-content__wrap{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
</style>